import React from 'react';
import { MainServices } from '../../Components/Services/ServiceList';
import { Link } from 'react-router-dom';
import Service from '../../Components/Services/Service';

const FullServices = () => {
    return (
        <div className="section-dark">
            <div className="container">
                <h2 className="section-title">Choose from our wide array of bespoke and off the shelf services</h2>
                <p className='services-heading'>Here to help your business succeed with technology</p>
                <hr className='services-hr' />
                <div className="row">
                    {MainServices.length > 0 && MainServices.map((service, i) => <Service key={i} heading={service.heading} icon={service.icon} description={service.description} />)}
                </div>
                <div className='row'>
                    <div className='col'>
                        <Link className="btn btn-primary-custom btn-lg" to="/contact" role="button">How can we help?</Link>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default FullServices;