import React from 'react';
import { Link } from 'react-router-dom';
import './ApproachSection.scss';
import Image from '../../Assets/Images/pexels-thecoachspace-2977547.jpg';

const ApproachSection = () => {
    return (
        <div className="approach-section">
            <div className="container">
                <div className="row">
                    {/* <div className='col-12-md col-lg-5 mt-5'>
                        <img src={Image} alt="Our Unique Approach" className="img-fluid" />
                    </div> */}
                    <div className='col'>
                        <p className="section-subHeading">The 13Tech Approach</p>
                        <h2 className='section-title'>What makes us different</h2>
                        <p>At 13Tech, we guide startups from idea to production, offering comprehensive support to navigate the complexities of the development process. We simplify technical jargon, making it accessible and understandable, and provide practical, no-nonsense advice tailored to your specific needs. Our personalized approach ensures that we understand and address the unique challenges of each startup, offering solutions aligned with your vision. By sharing our expertise through training sessions, detailed documentation, and ongoing support, we empower you to build your own capabilities. </p>
                        <hr className='approach-hr' />
                        <p className='approach-attention'>Join us on the journey to technological excellence, and let’s turn your ideas into reality together.</p>
                        <Link to="/about" role="button"><button className='btn btn-secondary-custom btn-lg'>Our Unique Offering</button></Link>

                    </div>
                </div>
            </div>
        </div >
    );
};

export default ApproachSection;