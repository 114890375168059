import React from 'react';
import LineIcon from 'react-lineicons';
import './ProcessSection.scss';
import { Link } from 'react-router-dom';

const ProcessSection = () => {
    return (
        <div className="section-dark">
            <div className="container">
                <h2 className="section-title mb-5">Our Process</h2>
                <hr className='process-hr' />
                <div className="row">
                    <div className='col-12'>

                    </div>
                    <div className="col-md-4">
                        <div className="process-step">
                            <LineIcon name="search" alt="We start by understanding your needs and goals." />
                            <h5 className='mt-4 process-title'>Discover</h5>
                            <p>We start by understanding your needs and goals.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="process-step">
                            <LineIcon name="write" alt="We create a detailed plan tailored to your project." />
                            <h5 className='mt-4 process-title'>Plan</h5>
                            <p>We create a detailed plan tailored to your project.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="process-step">
                            <LineIcon name="cogs" alt="We implement the plan with precision and expertise." />
                            <h5 className='mt-4 process-title'>Execute</h5>
                            <p>We implement the plan with precision and expertise.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-centre">
                        <Link to="/services">  <button className="btn btn-primary-custom btn-lg mt-5">Learn More</button></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProcessSection;