import React, { useEffect } from 'react';
import './Services.scss';
import ProcessSection from '../../Components/Process/ProcessSection';
import ContactSection from '../../Components/Contact/ContactSection';
import { Link } from 'react-router-dom';
import InfoSection from '../../Components/InfoSection/InfoSection';
import FullServices from './FullServices';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';


const Services = () => {

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: "/services",
            title: "Service"
        });
    }, []);

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>Our Services</title>
                <meta name='description' content="Explore the wide range of services offered by 13Tech, including DevOps consultancy, software architecture, technical due diligence, project management, and more. Tailored solutions for startups to achieve technological excellence." />
            </Helmet>
            <InfoSection heading="Our Services" subHeading="Explore our wide range of services tailored for startups"
                body="13Tech are able to help your Startup or Scaleup move fast by levergaing a vast array of technical expertise.
                 Specialising in simplifying everything from the hiring process to app and software development.
                 If you're not sure where to start, you're in the right place!
                  So whether you're looking for a DevOps guidance, software design & development, technical due diligence to help understand your current tech stack, help gathering technical requirements for new projects, project management, hiring and training, technical documentation to make sense of processes, guidance on compliance and security, help with system integration or developing a best practice cloud strategy, we've got you covered."
                theme="light" link="/contact" buttonText="Tell us how we can help" attentionGrabber="Fast track your tech journey" />

            <FullServices />
            <div className="section-light">
                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <p className='section-subHeading'>Whevever you are in your journey from Startup to ScaleUp 13Tech can help</p>
                            <h2 className="section-title">Looking for a service we haven't listed?</h2>
                            <hr className='contact-hr' />
                            <p>We're here to help so please get in touch and tell us what you're looking for and if we can't help directly, we're sure to be able to point you in the right direction.</p>
                            <Link to="/contact">  <button className='btn btn-secondary-custom btn-lg mt-4' >Chat with us</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <ProcessSection />
            <ContactSection />
        </>
    );
};

export default Services;
