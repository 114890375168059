export const ServicesPreview = [
    {
        icon: "infinite",
        heading: "DevOps Consultancy"
    },
    {
        icon: "ux",
        heading: "Software Architecture"
    },
    {
        icon: "magnifier",
        heading: "Technical Due Diligence"
    },
    {
        icon: "pencil-alt",
        heading: "Requirements Gathering and Analysis"
    },
    {
        icon: "handshake",
        heading: "Technical Hiring and Training"
    },
    {
        icon: "cloud",
        heading: "Cloud Strategy"
    }
];

export const MainServices = [
    {
        heading: 'DevOps Consultancy',
        description: 'Optimize your development and deployment processes with our expert DevOps consultancy. We help you implement and manage CI/CD pipelines, infrastructure automation, and container orchestration to ensure smooth and efficient operations.',
        icon: "infinite"
    },
    {
        heading: 'Software Architecture',
        description: 'Design scalable, reliable, and secure software systems tailored to your startup’s requirements. Our architecture consultancy ensures your software is built on a strong foundation, ready to grow with your business.',
        icon: "ux"
    },
    {
        heading: 'Software Development Consultancy',
        description: 'Leverage our backend development expertise to build robust software solutions. We provide comprehensive code reviews, best practices, and guidance to help you develop high-quality software.',
        icon: 'code-alt'
    },
    {
        heading: 'Technical Due Diligence',
        description: 'Evaluate the technical aspects of potential acquisitions or your own tech stack with our thorough due diligence services. We provide detailed technology assessments and risk analysis to support your investment decisions.',
        icon: "magnifier"
    },
    {
        heading: 'Requirements Gathering and Analysis',
        description: 'Conduct detailed stakeholder interviews and create comprehensive technical documentation to ensure your project’s success. We help you gather and analyze requirements effectively.',
        icon: "pencil-alt"
    },
    {
        heading: 'Project Management',
        description: 'Implement agile methodologies and best practices with our project management services. We help you plan, monitor, and deliver projects on time and within budget.',
        icon: 'briefcase'
    },
    {
        heading: 'Technical Hiring and Training',
        description: 'Assist in technical hiring by conducting interviews and assessments. Our training programs help upskill your team in the latest technologies and best practices.',
        icon: "handshake"
    },
    {
        heading: 'Technical Documentation',
        description: 'Create clear and detailed technical documentation for your projects. Our documentation services ensure your team and stakeholders have the information they need to succeed.',
        icon: 'write'
    },
    {
        heading: 'Communication and Simplification',
        description: 'Simplify complex technical concepts for non-technical audiences. We help bridge the gap between technical and non-technical stakeholders to ensure effective communication.',
        icon: 'comments'
    },
    {
        heading: 'Compliance and Security',
        description: 'Conduct security audits and ensure compliance with industry standards. We provide guidance on GDPR and data protection strategies to safeguard your business.',
        icon: 'protection'
    },
    {
        heading: 'System Integration',
        description: 'Integrate various systems to work seamlessly together. Our integration services ensure smooth interoperability between different software and hardware components.',
        icon: 'code'
    },
    {
        heading: 'Cloud Strategy',
        description: 'Develop and implement cloud strategies for startups. We help you leverage cloud technologies to enhance scalability, performance, and cost-efficiency.',
        icon: "cloud"
    }
];