import React, { useEffect } from 'react';
import ContactSection from '../../Components/Contact/ContactSection';
import ServicesSection from '../../Components/Services/ServicesSection';
import Success from '../../Assets/Images/pexels-ninauhlikova-725255.jpg';
import InfoSection from '../../Components/InfoSection/InfoSection';
import RowWithImage from '../../Components/RowWithImage/RowWithImage';
import Advice from '../../Assets/Images/pexels-fauxels-3183183.jpg';
import SnippetSection from '../../Components/SnippetSection/SnippetSection';
import { ServicesPreview } from '../../Components/Services/ServiceList';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

const About = () => {

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: "/about",
            title: "About"
        });
    }, []);

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>About 13Tech</title>
                <meta name='description' content="Learn about 13Tech, a team of experienced DevOps engineers, software architects, developers, cloud experts, and infrastructure engineers. We specialize in guiding startups from concept to production with personalized consultancy and support." />
            </Helmet>
            <InfoSection heading="About 13Tech" subHeading="From Startup to Scaleup we're here to help"
                body="Welcome to 13Tech, where we specialize in empowering startups to reach their full potential through top-tier technical consultancy services. Founded by experienced DevOps engineers, software architects, software developers, cloud experts, and infrastructure engineers, our mission is to provide comprehensive support and guidance, turning your innovative ideas into successful products. We understand the unique challenges startups face, from limited resources and rapid scaling needs to the pressure of delivering cutting-edge solutions quickly. Our expertise lies in simplifying these complexities, making the path to success smoother and more achievable."
                theme="light" link="/services" buttonText="Explore our services" attentionGrabber="Tech consultancy done differently" />

            <RowWithImage image={Success} heading="Clearing the pathway to success" subHeading="Strong, clear and simple communication" theme={"dark"}
                link="/contact" buttonText="Get in touch"
                paragraphs={["We pride ourselves on our ability to demystify technical jargon and communicate complex concepts in a clear, understandable manner. We offer practical, no-nonsense advice tailored to your specific needs, focusing on solutions that drive real results. Our personalized approach ensures that we fully understand your vision and goals, providing customized support from initial requirements gathering to final deployment. Through our training sessions, detailed documentation, and ongoing support, we empower you to build your own capabilities and navigate future challenges with confidence."]} />

            <SnippetSection heading="Need advice on building your tech team?" subHeading="Ready to start hiring?"
                body="Hiring for a Startup can be a daunting process. Not sure on which positions to hire for? Confused about roles? 13Tech can cost effectively help build your tech team using our extensive industry knowledge and expertise" />

            <RowWithImage image={Advice} heading="Not your average Tech Consultants" subHeading="Expert advice, reliable solutions." theme={"light"}
                link="/contact" buttonText="Talk to us"
                paragraphs={["While our main function is consultancy, 13Tech is also equipped to build tailored solutions when needed. Our team of skilled professionals can develop robust, scalable software and implement cutting-edge infrastructure solutions to meet your specific requirements. Additionally, we recognize that startups often need more than just advice to get off the ground. That’s why we also help you find the best partners in the industry. Whether you need additional software development, security solutions, cloud services, or specialized IT infrastructure, we can connect you with trusted companies that provide high-quality, cost-effective solutions. Our goal is to ensure that you receive the best value and exactly what you need, even if 13Tech is not the one building it. By leveraging our extensive network and industry expertise, we help you assemble the perfect team to bring your vision to life."]}
            />

            <ServicesSection services={ServicesPreview} />
            <ContactSection />
        </>
    );
};

export default About;