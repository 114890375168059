import React from 'react';
import { Link } from 'react-router-dom';

const RowWithImage = ({ heading, subHeading, paragraphs, image, buttonText, link, theme }) => {
    return (
        <div className={`section-${theme}`}>
            <div className="container">
                <div className="row">
                    {theme === "light" &&
                        <div className='col-12-md col-lg-5 mt-5'>
                            <img src={image} alt="Mission" className="img-fluid" />
                        </div>
                    }
                    <div className='col-12-md col-lg-7'>
                        <h2 className="section-title">{heading}</h2>
                        <p className='section-subHeading'>
                            {subHeading}
                        </p>
                        {paragraphs && paragraphs.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                        <Link to={link} role="button"><button className={`btn btn-${theme === "dark" ? "primary" : "secondary"}-custom btn-lg`}>{buttonText}</button></Link>
                    </div>
                    {theme === "dark" &&
                        <div className='col-12-md col-lg-5 mt-5'>
                            <img src={image} alt="Mission" className="img-fluid" />
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};

export default RowWithImage;