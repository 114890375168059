import React from 'react';
import { Link } from 'react-router-dom';
import './ContactSection.scss';

const ContactSection = () => {
    return (
        <div className="section-light">
            <div className="container">
                <div className='row'>
                    <div className='col'>
                        <p className='section-subHeading'>Ready to take your business to the next level? </p>
                        <h2 className="section-title">Get In Touch</h2>
                        <hr className='contact-hr' />
                        <p> Contact us today to learn how we can help you transform your technology and drive your business forward.</p>
                        <Link to="/contact" role="button"><button className='btn btn-secondary-custom btn-lg mt-4'>Contact Us</button></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;