import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import Contact from './Pages/Contact/Contact';
import Layout from './Components/Layout/Layout';
import About from './Pages/About/About';
import ScrollTop from './Utilities/ScrollTop';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

function App() {
  const TRACKING_ID = "G-N14C2YVTMR";
  ReactGA.initialize(TRACKING_ID);

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>13Tech | Not you're average Tech Consultancy</title>
        <description>Discover 13Tech, your trusted partner for empowering startups with top-tier technical consultancy services. From idea to production, we provide comprehensive support, demystify technical jargon, and offer practical, no-nonsense advice to help your startup succeed.</description>
      </Helmet>
      <Router>
        <ScrollTop>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </Layout>
        </ScrollTop>
      </Router>
    </>
  );
}

export default App;
