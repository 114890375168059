import React from 'react';
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <p>&copy; {new Date().getFullYear()} 13Tech. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;