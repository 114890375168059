import React from 'react';
import './MobileMenu.scss';
import Logo from '../../Assets/Images/13Tech.png';
import { Link } from 'react-router-dom';
import { Fade as Hamburger } from 'hamburger-react'

const MobileMenu = (props) => {
    return (
        <div className={`mobMenu ${props.mobMenu ? "open" : "hidden"}`}>
            <div className="row no-gutters">
                <Hamburger color="white" rounded toggled={true} toggle={() => props.toggleMenu()} />
                <div className="logo pt-5">
                    <img className="img-fluid" width="400px" height="120px" src={Logo} alt="13Tech" />
                </div>
            </div>
            <div className="menu-links">
                <ol>
                    <li>
                        <Link to="/" onClick={() => props.toggleMenu()}>Home</Link>
                    </li>
                    <li>
                        <Link to="/about" onClick={() => props.toggleMenu()}>About</Link>
                    </li>
                    <li>
                        <Link to="/services" onClick={() => props.toggleMenu()}>Services</Link>
                    </li>
                    <li>
                        <Link to="/contact" onClick={() => props.toggleMenu()}>Contact Us</Link>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default MobileMenu;