import React from 'react';
import './SnippetSection.scss';

const SnippetSection = ({ heading, subHeading, body }) => {
    return (
        <div className="section-alt">
            <div className="container">
                <div className="row">
                    <div className='col'>
                        <p className="alt-subHeading">{subHeading}</p>
                        <h2 className='section-title'>{heading}</h2>
                        <hr className="alt-hr" />
                        <p className='alt-body'>{body}</p>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SnippetSection;