import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../Assets/Images/13Tech.png';
import './NavBar.scss';
import { Fade as Hamburger } from 'hamburger-react';

const NavBar = (props) => {
    return (
        <nav className="navbar navbar-expand-lg fixed-top">
            <div className="container-xxl d-flex justify-content-between align-items-center">
                <Link className="navbar-brand" to="/">
                    <img alt="13Tech" width="150px" height="" id="brand-logo" className="img-fluid" src={Logo} />
                </Link>
                <div className="d-lg-none d-flex align-items-center">
                    <Hamburger rounded toggled={false} toggle={() => props.toggleMenu()} />
                </div>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item active">
                            <NavLink exact={true} className="nav-link" to="/">Home</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/services">Services</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/about">About</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/contact">Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
