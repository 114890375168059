import React, { useEffect } from 'react';
import './Contact.scss';
import ContactForm from '../../Components/ContactForm/ContactForm';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

const Contact = () => {

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: "/contact",
            title: "Contact"
        });
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;

        window.scrollTo({
            top: elementPosition,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>Contact 13Tech</title>
                <meta name='description' content="Get in touch with 13Tech to discuss how our expert consultancy services can help your startup achieve its goals. Contact us for tailored solutions, practical advice, and comprehensive support from industry professionals." />
            </Helmet>
            <div className="section-light">
                <div className="container">
                    <div className='row'>
                        <div className='col'>
                            <p className='section-subHeading'>Ready to take your business to the next level? </p>
                            <h2 className="section-title">Get In Touch</h2>
                            <hr className='contact-hr' />
                            <p>Use the form below to contact us today to learn how we can help you transform your technology and drive your business forward.</p>
                        </div>
                    </div>
                </div>
            </div>
            <ContactForm />
            <div className="section-light">
                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <p className='section-subHeading'>Whevever you are in your journey from Startup to ScaleUp 13Tech can help</p>
                            <h2 className="section-title">Looking for a service we haven't listed?</h2>
                            <hr className='contact-hr' />
                            <p>We're here to help so please use the form above to tell us what you're looking for and if we can't help directly, we're sure to be able to point you in the right direction.</p>
                            <button className='btn btn-secondary-custom btn-lg mt-4' onClick={() => scrollToSection('contact-form')}>Chat with us</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
