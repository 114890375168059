import React from 'react';
import { Link } from 'react-router-dom';
import Service from './Service';
import './ServicesSection.scss';

const ServicesSection = ({ services }) => {

    return (
        <div className="section-dark">
            <div className="container">
                <h2 className="section-title">Our Services</h2>
                <p className='services-heading'>We offer a range of services to help your startup succeed</p>
                <hr className='services-hr' />
                <div className="row">
                    {services.length > 0 && services.map((service, i) => <Service key={i} heading={service.heading} icon={service.icon} />)}
                </div>
                <div className='row'>
                    <div className='col'>
                        <Link className="btn btn-primary-custom btn-lg" to="/services" role="button">Explore Our Services</Link>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ServicesSection;