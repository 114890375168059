import React from 'react';
import { Link } from 'react-router-dom';
import './InfoSection.scss';

const InfoSection = ({ heading, subHeading, body, theme, attentionGrabber, link, buttonText }) => {
    return (
        <div className={`section-${theme}`}>
            <div className="container">
                <div className="row">
                    <div className='col'>
                        <p className="section-subHeading">{subHeading}</p>
                        <h2 className='section-title'>{heading}</h2>
                        <p>{body}</p>
                        <hr className={`${theme}-hr`} />
                        <p className='attentionGrabber'>{attentionGrabber}</p>
                        <Link to={link}><button className='btn btn-secondary-custom btn-lg'>{buttonText}</button></Link>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default InfoSection;