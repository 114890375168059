import React from 'react';
import { Link } from 'react-router-dom';
import './MissionSection.scss';
import Image from '../../Assets/Images/pexels-thecoachspace-2977547.jpg';

const MissionSection = () => {
    return (
        <div id="our-mission" className="mission-section">
            <div className="container">
                <div className="row">
                    <div className='col-12-md col-lg-7'>
                        <h2 className="section-title">Our Mission</h2>
                        <p className='section-subHeading'>
                            At 13Tech, our mission is to empower startups to reach their full potential by providing cost effective top-tier technical consultancy services.
                        </p>
                        <p>
                            We understand the unique challenges that startups face, such as limited resources, rapid scaling needs, and the pressure to deliver innovative solutions quickly. Our goal is to help startups navigate these challenges with ease.
                        </p>
                        <p>
                            We are dedicated to offering tailored consultancy services that meet the specific needs of startups, ensuring they can focus on growth and innovation without being bogged down by technical complexities. Our team of experts provides support in optimizing development processes, designing scalable architectures, ensuring compliance and security, and much more.
                        </p>
                        <p>
                            Let us help you turn your vision into reality by leveraging our comprehensive range of services and industry expertise. Together, we can drive your startup towards success.
                        </p>
                        <Link to="/about" role="button"><button className='btn btn-secondary-custom btn-lg'>About Us</button></Link>

                    </div>
                    <div className='col-12-md col-lg-5 mt-5'>
                        <img src={Image} alt="Mission" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MissionSection;