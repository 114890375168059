import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollTop = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // Directly set the scroll position to the top left corner
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'  // 'auto' ensures there's no smooth scrolling
        });
    }, [location]);

    return children;
};

export default ScrollTop;
