import React, { useState } from 'react';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import './Layout.scss';
import MobileMenu from '../MobileMenu/MobileMenu';

const Layout = ({ children }) => {

    const [mobMenu, toggleMobMenu] = useState(false);

    function toggleMenu() {
        toggleMobMenu(!mobMenu);
    }

    return (
        <>
            <MobileMenu mobMenu={mobMenu} toggleMenu={toggleMenu} />
            <NavBar toggleMenu={toggleMenu} mobMenu={mobMenu} />
            <main className="container-fluid content main">
                {children}
            </main>
            <Footer />
        </>
    );
};

export default Layout;