import React from 'react';
import LineIcon from 'react-lineicons';
import { FadeInOnScroll } from '../../Utilities/FadeInOnScroll';

const Service = ({ heading, icon, description }) => {
    const [ref] = FadeInOnScroll();

    return (
        <div ref={ref} className="col-md-4 feature">
            <LineIcon name={icon} alt={heading} />
            <h3>{heading}</h3>
            <p>{description}</p>
        </div>
    );
};

export default Service;