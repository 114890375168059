import React, { useState } from 'react';
import './ContactForm.scss';
import { Link } from 'react-router-dom';
import { postContactFormAsync } from '../../Infrastructure/ApiService';

const ContactForm = () => {
    const [display, setDisplay] = useState("form");

    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        phone: '',
        email: '',
        message: ''
    });


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevState) => {
                if (checked) {
                    return { ...prevState, services: [...prevState.services, value] };
                } else {
                    return { ...prevState, services: prevState.services.filter(service => service !== value) };
                }
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await postContactFormAsync(formData);
        setDisplay("submitted");
        console.log('Form data submitted:', formData);
    };

    return (
        <>
            {display === "form" ?
                <div className="contact-page section-dark" id="contact-form">
                    <div className="container p-md-5">
                        <div className='row'>
                            <div className='col-xs-12 col-md-8 offset-md-2 px-md-5'>
                                <h2 className="section-title">Contact Us</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            className="form-control"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="companyName">Company Name</label>
                                        <input
                                            type="text"
                                            id="companyName"
                                            name="companyName"
                                            className="form-control"
                                            value={formData.companyName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            className="form-control"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            className="form-control"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary-custom btn-lg">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="contact-page section-dark" id="contact-form">

                    <div className="container p-md-5">
                        <div className='row'>
                            <div className='col-xs-12 col-md-8 offset-md-2 px-md-5'>
                                <h2 className="section-title">Thank you</h2>
                                <p>Thank you for contacting us. We will be in touch shortly.</p>
                            </div>
                        </div>
                    </div>
                    <Link to="/"><button className="btn btn-primary-custom btn-lg">Back to Home</button></Link>
                </div>

            }
        </>
    );
};

export default ContactForm;