import React, { useEffect } from 'react';
import ContactSection from '../../Components/Contact/ContactSection';
import ServicesSection from '../../Components/Services/ServicesSection';
import './Home.scss';
import ProcessSection from '../../Components/Process/ProcessSection';
import ApproachSection from '../../Components/Approach/ApproachSection';
import MissionSection from '../../Components/Mission/MissionSection';
import { ServicesPreview } from '../../Components/Services/ServiceList';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

const Home = () => {

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: "/",
            title: "Home"
        });
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        const offset = 78; // Height of the navbar
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>13Tech | Not you're average Tech Consultancy</title>
                <meta name='description' content="Discover 13Tech, your trusted partner for empowering startups with top-tier technical consultancy services. From idea to production, we provide comprehensive support, demystify technical jargon, and offer practical, no-nonsense advice to help your startup succeed." />
            </Helmet>
            <div className="hero-section">
                <div className="overlay"></div>
                <div className="hero-content">
                    <h1>Empowering startups with cost effective top-tier technical consultancy services</h1>
                    <hr className='hero-hr' />
                    <button className="btn btn-primary-custom btn-lg" onClick={() => scrollToSection('our-mission')}>Learn More</button>
                </div>
            </div>

            <MissionSection />

            <ServicesSection services={ServicesPreview} />

            <ApproachSection />

            <ProcessSection />

            <ContactSection />

        </>
    );
};

export default Home;
